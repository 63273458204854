import React, { useEffect, useState } from "react";
import BackToTop from "../Components/backToTop";
import BetweenContent from "../Components/ads/BetweenContent";
import ParallaxCode from "../Components/ads/ParallaxCode";
import BelowFeaturedImages from "../Components/ads/BelowFeaturedImages";
import LeftSideAd from "../Components/ads/LeftSideAd";
import RightSideAd from "../Components/ads/RightSideAd";

const LifeStyleView = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 1440);

  useEffect(() => {
    const handleResize = () => {
      setIsDesktop(window.innerWidth > 1440);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [isDesktop]);

  return (
    <div>
      {isDesktop && (
        <>
          <LeftSideAd />
          <RightSideAd />
        </>
      )}
      <div className="container max-w-7xl mx-auto px-4">
        <div className="">
          <h3
            className="lg:max-w-[50%] mx-auto text-[40px] text-center mt-10 mb-5 font-semibold"
            style={{
              fontFamily: "Playfair Display",
              fontSize: "clamp(1.5rem, 4vw, 3rem)",
            }}
          >
            Hair Trends that Must-Try According to Expert
          </h3>
          <img
            src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/billionaireclubs/trendImg.jpg"
            alt=""
          />
          <BetweenContent />
          <div
            className="lg:w-[60%] mx-auto w-full"
            style={{ fontFamily: "Playfair Display" }}
          >
            <p className="mt-4 md:text-[20px] text-[17px]">
              The experience of having hair is quite personal. It is a component
              of who you are and how you show yourself to the outside world, and
              even the smallest alteration may send you into a downward spiral
              or make you feel ecstatic (who has not sobbed over a horrible
              haircut or coloring job?). It is just as perilous to rely on a
              stylist to get your desired outcomes as it is to rely on yourself
              to discover a haircut that makes you feel good about yourself.
              This is ELLE.com's 2025 hair trends guide.
            </p>
            <p className="mt-4 md:text-[20px] text-[17px]">
              As the year draws to a close, there is no better moment to change
              up your characteristic style. TikTok users have created a plethora
              of hairstyles in the last year, ranging from '70s-inspired fringe
              curtain bangs to buns reminiscent of Sophie Richie. In order to
              help you create your own trademark style, we have put together a
              list of the top six trends to watch out for in 2024.
            </p>
            <p className="mt-4 md:text-[20px] text-[17px]">
              We looked at product launches, examined the red carpet and
              runways, and spoke with seven hair stylists and industry
              professionals who are well-versed in salon chair operations to
              determine our top six hair trends for 2024. Everyone will discover
              a trend that appeals to them, whether they are minimalists,
              maximalists, or somewhere in between. Who knows? You can also
              choose to bleach your hair or use hair extensions to give it more
              volume. As usual, the options are virtually limitless.
            </p>
          </div>
          <ParallaxCode />
          <div className="">
            <h3
              className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.5rem, 4vw, 2.8rem)",
              }}
            >
              Volume, Volume, Volume
            </h3>
            <div className="lg:w-[60%] mx-auto w-full flex justify-center">
              <div>
                <div className="flex justify-center">
                  <img
                    src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/billionaireclubs/image+123.jpg"
                    alt=""
                    className=""
                  />
                </div>

                <p className="mt-4 md:text-[18px] text-[17px]">
                  I believe that voluminous designs will be a major trend in
                  2024 since the mob wife look is still in vogue. Big, bouncy
                  blowouts and purposefully messy updos are going to be hot this
                  year, according to Los Angeles-based celebrity hairdresser
                  Laura Polko.
                </p>
                <p className="mt-4 md:text-[20px] text-[17px]">
                  2024 is the year to let loose, if 2023 was about tight,
                  slicked-back buns. The Los Angeles-based celebrity hairdresser
                  Justine Marjan also forecasts "layered cuts with loads of
                  volume." A delicate layered cut is necessary to maintain the
                  additional texture and root lift of the naturally voluminous
                  curls and bouncy blowouts that are becoming more and more
                  common. Marjan suggests adding fullness with extensions like
                  those from Great Lengths if you have fine hair that does not
                  take well to teasing. To keep everything in place, use a lot
                  of hairspray to lock in any voluminous style.
                </p>
              </div>
            </div>
          </div>
          <BelowFeaturedImages />
          <div className="">
            <h3
              className="py-3 text-[45px] border-t border-b border-[#C7CACC] text-center mt-12 mb-7 font-semibold"
              style={{
                fontFamily: "Playfair Display",
                fontSize: "clamp(1.5rem, 4vw, 2.8rem)",
              }}
            >
              Au Natural
            </h3>
            <div className="lg:w-[60%] mx-auto w-full flex justify-center">
              <div>
                <div className="flex justify-center">
                  <img
                    src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/billionaireclubs/au-Natural.jpg"
                    alt=""
                    className=""
                  />
                </div>

                <p className="mt-4 md:text-[18px] text-[17px]">
                  This is going to be your year if you are debilitated of going
                  to the salon on a every day premise and your hair is inquiring
                  for a excursion. The up and coming months will be all almost
                  falling in adore with the hair color and surface your mother
                  gave you, agreeing to celebrity beautician Cody Renegar, who
                  partitions his time between Los Angeles, Washington, D.C., and
                  Arkansas.
                </p>
                <p className="mt-4 md:text-[20px] text-[17px]">
                  In 2024, au naturale, classic styles with a individual bend
                  will be prevalent. I see people going back to where they came
                  from. At the minute, individuals are especially floating
                  toward low-maintenance alternatives," he clarifies.
                  Subsequently, you can be beyond any doubt that you are on
                  fashion whether you are learning to work with your wavy hair
                  or letting your roots develop in. "I am taking note that more
                  and more individuals crave to acknowledge their characteristic
                  unpleasantness. Agreeing to beautician Kelly Macedo,
                  proprietor of Insides Hair in Los Angeles, "in the event that
                  you have a pleasant hair style, all you require is a small
                  item to assist—whether to de-frizz or advance curls—and you
                  ought to be able to have a dazzling air-dry."
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default LifeStyleView;
