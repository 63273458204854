import React from "react";
import BackToTop from "../Components/backToTop";
import EndOfArticle from "../Components/ads/EndOfArticle";

const AboutUs = () => {
  return (
    <div className="">
      <img
        src="https://api-ap-south-mum-1.openstack.acecloudhosting.com:8080/billionaireclubs/about-us.jpg"
        className="w-[100%] lg:h-[500px] h-[300px]"
        alt="about_img"
      />
      <EndOfArticle />
      <div className="mt-6 container max-w-7xl mx-auto">
        <h1
          className="lg:text-[40px] text-[30px] text-[#000] font-bold flex justify-center"
          style={{ fontFamily: "Playfair Display" }}
        >
          ABOUT US
        </h1>
        <div className="mx-4">
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            Welcome to billionairclubs.com, the go-to resource for thorough and
            current data about the world's richest individuals. Our goal is to
            offer in-depth analyses of the accomplishments and lifestyles of the
            wealthiest and most successful individuals on the planet.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            Our website features carefully selected lists of the wealthiest
            people, along with detailed accounts of their business endeavors,
            revenue streams, accomplishments, and other relevant details. We
            want to provide a more thorough comprehension of the elements that
            lead to their enormous success and fortune.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            In order to keep you informed and motivated by these titans of
            finance, our staff is committed to providing factual and captivating
            material. Our website provides insightful information and engrossing
            tales for anybody interested in business, education, or the richest
            people in the world.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            Additionally, cookies help us deliver the best possible service and
            improve your browsing experience. You accept our usage of cookies in
            accordance with our privacy policy if you keep using our website.
          </p>
          <p className="text-[17px] md:text-[18px] lg:text-[19px] mt-3">
            We appreciate your interest in billionairclubs.com. Keep checking
            back with us to discover the adventures of the world's wealthiest
            people.
          </p>
        </div>
      </div>
      <BackToTop />
    </div>
  );
};

export default AboutUs;
